.about-section .container {
  p {
    margin-bottom: 1rem;
  }
  pre{
    display: flex;
    flex-direction: column;
  }
  hr{
    border: 0.07rem solid var(--output-bg-color);
    width: 100%;
  }

  ul,
  ol {
    padding-left: 1.625em;
    padding-bottom: 0.5rem;
  }
  li,h2,strong {
    margin-bottom: 0.3rem;
  }
}
