:root {
  --input-text-color: #444;
  --bg-color: #f5f5f5;
  --output-bg-color: #ededed;
  --br-textareas: 0.5rem;
  --color-two: #3d6eff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h2{
  color: var(--color-two);
}

body,
html {
  background: var(--bg-color);
}
textarea {
  border: none;
  resize: none;
  outline: none;
  padding: 1rem;
}
div {
  display: inherit;
}

.section {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  
}

.container {
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 90%;
  justify-items: center;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}
.container h1 {
  user-select: none;
  color: var(--color-two);
  text-align: center;
}
.form{
    border: 0.5px solid var(--color-two);
    border-radius: var(--br-textareas);
}

.lang-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-items: center;
  background: var(--color-two);
  color: white;
  padding: 0.5rem;
  border-radius: var(--br-textareas) var(--br-textareas) 0 0;
  align-items: center;
  user-select: none;
}
.switch {
  padding: 0.5rem;
  border-radius: 2rem;
  line-height: 0;
  box-shadow: 0 0 5px 1px white;
  cursor: pointer;
  transition: box-shadow 0.2s;
  background: white;
  color: var(--color-two);
}
.switch:hover {
  box-shadow: 0 0 10px 1px white;
}
#langSelector {
  display: none;
}

.textarea-input {
  color: black;
  border-radius: 0 0 0 var(--br-textareas);
  flex: 1 1;
}
.textarea-output {
  background: var(--output-bg-color);
  color: var(--input-text-color);
  border-radius: 0 0 var(--br-textareas) 0;
  flex: 1 1;
  user-select: all;
}

.textareas-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: var(--br-textareas);
}

.textareas-container > div{
    flex: 1 1;
    position: relative;
}

.tooltip{
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border-radius: .1rem;
  color: white;
  background: var(--color-two);
  cursor: pointer;
  
}
.copy-button{
  padding: 0.2rem;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 8.75rem;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 0.375rem;
  padding: 0.3125rem;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -4.6875rem;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0.3125rem;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

footer {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 20rem;
  color: white;
  background: var(--color-two);
}

footer a {
  color: var(--bg-color);
}


