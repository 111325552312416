header{
    background:var(--color-two);
    .header-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        .logo{
            font-size: 1.5rem;
            font-weight: bold;
            color: white;
        }
        a{
            color: white;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        .logo a:hover{
            text-decoration: none;
        }
        .nav-links{
            list-style: none;
            display: flex;
            gap: 1rem;
        }
    }
}